// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jxcsZQZ9VuZ5OBIx9jqi {
  border-radius: var(--radius-xs, 8px);
  padding: 12px 16px;
  border: 1px solid var(--gray-stroke, #dfe0eb);
  height: 50px;
  overflow: hidden;
  color: var(--gray-text-strong, #2c3059);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
}
.jxcsZQZ9VuZ5OBIx9jqi:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
  border: unset;
}

.MlNOF1gRY5Qhyry3KVEe {
  padding: 10px 12px 10px 16px;
  height: 50px;
  overflow: hidden;
  color: var(--gray-text-strong, #2c3059);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid var(--error-stroke-weak, #ff8aa1) !important;
  border-radius: var(--radius-xs, 8px);
}
.MlNOF1gRY5Qhyry3KVEe:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
}`, "",{"version":3,"sources":["webpack://./src/shared/tg-typeahead/tg-typeahead.module.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;EACA,kBAAA;EACA,6CAAA;EACA,YAAA;EACA,gBAAA;EACA,uCAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AACI;EACI,aAAA;EACA,2EAAA;EACA,aAAA;AACR;;AAGA;EACI,4BAAA;EACA,YAAA;EACA,gBAAA;EACA,uCAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EAOA,0BAAA;EACA,6BAAA;EACA,8DAAA;EACA,oCAAA;AANJ;AAFI;EACI,aAAA;EACA,2EAAA;AAIR","sourcesContent":[".tg-select-box {\n    border-radius: var(--radius-xs, 8px);\n    padding: 12px 16px;\n    border: 1px solid var(--gray-stroke, #dfe0eb);\n    height: 50px;\n    overflow: hidden;\n    color: var(--gray-text-strong, #2c3059);\n    text-overflow: ellipsis;\n    font-family: Inter;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 25.6px;\n\n    &:focus {\n        outline: none;\n        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;\n        border: unset;\n    }\n}\n\n.tgInputError {\n    padding: 10px 12px 10px 16px;\n    height: 50px;\n    overflow: hidden;\n    color: var(--gray-text-strong, #2c3059);\n    text-overflow: ellipsis;\n    font-family: Inter;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 25.6px;\n\n    &:focus {\n        outline: none;\n        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;\n    }\n\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n    border: 1px solid var(--error-stroke-weak, #ff8aa1) !important;\n    border-radius: var(--radius-xs, 8px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tg-select-box": `jxcsZQZ9VuZ5OBIx9jqi`,
	"tgInputError": `MlNOF1gRY5Qhyry3KVEe`
};
export default ___CSS_LOADER_EXPORT___;
