import React, { useEffect, useState } from "react";
import styles from "./timer.module.scss";
import { t } from "i18next";
import moment from "moment";
import TGButton from "../../shared/tg-button";
import TGButtonVariants from "../../shared/tg-button-variants";
import myTripStyle from "../../OSCI/src/pages/mytrips/signeduser/flight-details/flight-details.module.scss";

const Timer = (props: any) => {
  const {
    flightData,
    view,
    checkin,
    page,
    isTimer = false,
    isMyTrips = false,
  } = props;
  const [timeLeft, setTimeLeft] = useState<{
    days: any;
    hours: any;
    minutes: any;
  }>({ days: 0, hours: 0, minutes: 0 });
  const [isCheckin, setIsCheckin] = useState(false);
  const upcomingTrips = isMyTrips
    ? flightData?.filter((trip: { departureDateTimeUtc: moment.MomentInput }) =>
        moment
          .utc(trip.departureDateTimeUtc, "DD-MM-YY HH:mm")
          .isAfter(moment().utc())
      )
    : flightData?.flightDetails?.filter(
        (trip: { depatureDateUTC: moment.MomentInput }) =>
          moment
            .utc(trip.depatureDateUTC, "DD-MM-YY HH:mm")
            .isAfter(moment().utc())
      );
  const targetDate = isMyTrips
    ? upcomingTrips?.[0]?.departureDateTimeUtc
    : upcomingTrips?.[0]?.depatureDateUTC;

  useEffect(() => {
    const deadlineUTC = moment.utc(targetDate, "DD-MM-YY HH:mm");

    const startTime = deadlineUTC.clone().subtract(24, "hours");
    const endTime = deadlineUTC.clone().subtract(1, "hours");

    if (!deadlineUTC.isValid()) {
      return;
    }

    const calculateTimeLeft = () => {
      const nowUTC = moment().utc();
      const timeDifference = moment
        .duration(startTime.diff(nowUTC))
        .add(1, "minutes");
      const countDown = {
        days: Math.floor(timeDifference.asDays()),
        hours: timeDifference.hours(),
        minutes: timeDifference.minutes(),
      };
      if (nowUTC.isBefore(startTime)) {
        setTimeLeft(countDown);
      } else {
        setTimeLeft({
          days: "00",
          hours: "00",
          minutes: "00",
        });
      }
      setIsCheckin(
        nowUTC.isSameOrAfter(startTime) && nowUTC.isSameOrBefore(endTime)
      );
    };
    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [isCheckin, targetDate]);
  return (
    <>
      {!isMyTrips && (
        <div className={styles["check-in-container"]}>
          {isTimer && (
            <div className={styles["check-in-timer"]}>
              <span style={{ color: "white" }}>{t("label_check_in")}</span>
              <span>{`${timeLeft.days}d: ${timeLeft.hours}h: ${timeLeft.minutes}m`}</span>
            </div>
          )}
          <div className={styles["button-container"]}>
            <TGButton
              label={t("label_view_detail")}
              variant="secondary"
              customClassName={
                page !== "MB"
                  ? styles["custom-manage"]
                  : styles["custom-mange2"]
              }
              onClick={view}
            />
            {page !== "MB" && (
              <TGButton
                label={t("label_book_widget_check_in")}
                variant="primary"
                customClassName={styles["custom-checkin"]}
                disabled={!isCheckin}
                onClick={checkin}
              />
            )}
          </div>
        </div>
      )}
      {isMyTrips && (
        <>
          <div className={myTripStyle.checkinopens}>
            <span className={myTripStyle.checkinText}>
              {t("label_check_in")}
            </span>
            <span
              className={myTripStyle.checkinTimer}
            >{`${timeLeft.days}d: ${timeLeft.hours}h: ${timeLeft.minutes}m`}</span>
          </div>
          <div className={myTripStyle.checkinButtonCotainer}>
            <TGButtonVariants
              label={t("button_checkin")}
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="30px"
              boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              bgColor="#684B9B"
              padding="12px"
              textColor="#FFFFFF"
              fontFamily="Inter-Bold"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="160%"
              borderDisabled="0.791px solid #E3D4FE"
              bgColorDisabled="#E3D4FE"
              boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              bgColorHover="#684b9b"
              borderHover="0.791px solid #E3D4FE"
              boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              height="50px"
              width="170px"
              disabled={!isCheckin}
              onClick={props.checkin}
            ></TGButtonVariants>
          </div>
        </>
      )}
    </>
  );
};
export default Timer;
