import React, { ReactNode, useEffect, useState } from "react";
import { Tabs, Tab, Container, Row, Col } from "react-bootstrap";
import  "./tg-tabs.scss";
interface Props {
  variant?: string;
  className?: string;
  optionalUnderlineClassName?:string;
  optionalClassName?:string;
  children?: ReactNode;
  onSelect?: (selectedTab: number) => void;
  title?: string;
  activeIndex?: any
}

const TGTabs: React.FC<Props> = (props: Props) => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(()=>{
    if(props.activeIndex?.length === 2){
       setActiveTab(props.activeIndex[1])
    }
  },[props.activeIndex])

  const handleTabSelect = (selectedTab: any) => {
    setActiveTab(selectedTab);
    if (props.onSelect) {
      props.onSelect(selectedTab);
    }
  };

  return (
    <Container className={`${props.optionalClassName?props.optionalClassName:''}`}>
      <Tabs
        defaultActiveKey="0"
        id="uncontrolled-tab-example"
        className={`${props.variant === 'pills' ? '':`${props.optionalUnderlineClassName?props.optionalUnderlineClassName:''} `} ${props.className ? props.className : ""}`}      
        activeKey={activeTab}
        onSelect={handleTabSelect}
        variant={props.variant}
      >
        {/* This step is to check for a valid child element based on the Content entered between the TGTab component and to make it recursive */}
        {React.Children.map(props.children, (child, index) => {
          if (React.isValidElement(child)) {
            const { title } = child.props as Props;
            return (
              <Tab eventKey={index} title={title} key={index}>
                {child}
              </Tab>
            );
          }
        })}
      </Tabs>
    </Container>
  );
};

export default TGTabs;
