import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import { all, call } from "redux-saga/effects";
import osciRootReducer from "./OSCI/src/slice/RootReducer";
import dapiRootReducer from "./DAPI/src/slice/RootReducer";
import { persistStore, persistReducer, PURGE, FLUSH } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import osciRootSaga from "./OSCI/src/saga/rootSaga";
import dapiRootSaga from "./DAPI/src/saga/rootSaga";
import { FETCH_DATA_CONSTANT } from "./OSCI/src/saga/apiSaga";
import { airAwardPnrReset } from "./OSCI/src/slice/airAwardPnrSlice";
import { airAwardReviewPnrRMReset } from "./OSCI/src/slice/airAwardReviewPnrRMSlice";
import { airAwardReviewPnrCancelReset } from "./OSCI/src/slice/airAwardReviewPnrCancelSlice";
import { retrivePNRReset } from "./OSCI/src/slice/retrivePNRSlice";
import { airAwardBookingReset } from "./OSCI/src/slice/airAwardBookingSlice";
import { airAwardPriceReset } from "./OSCI/src/slice/airAwardPriceSlice";
import { airAwardTstReset } from "./OSCI/src/slice/airAwardTstSlice";
import {
  airAwardReviewPnrReset,
  savePageId,
} from "./OSCI/src/slice/airAwardReviewPnrSlice";

const sagaMiddleWare = createSagaMiddleware();

const persistConfigOSCI: any = {
  key: "osci",
  storage: sessionStorage,
  whitelist: [
    "profile",
    "airAwardPnr",
    "airAwardReviewPnrRM",
    "airAwardReviewPnrCancel",
    "flightInfo",
    "retrivePNRData",
    "airAwardBooking",
    "airAwardPrice",
    "airAwardTst",
    "airAwardReviewPnr",
    "redemption",
  ],
};
const persistConfigDAPI: any = {
  key: "dapi",
  storage: sessionStorage,
  whitelist: ["checkin"],
};
const persistedReducerOsci: any = persistReducer(
  persistConfigOSCI,
  osciRootReducer
);
const persistedReducerDapi: any = persistReducer(
  persistConfigDAPI,
  dapiRootReducer
);

const mainReducer = combineReducers({
  osciReducer: persistedReducerOsci,
  dapiReducer: persistedReducerDapi,
});

export const store = configureStore({
  reducer: mainReducer,
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare({
      serializableCheck: {
        ignoredActions: [FETCH_DATA_CONSTANT, PURGE, FLUSH],
      },
    }).concat(sagaMiddleWare),
});

const saga = () => {
  return function* getMainSaga() {
    yield all([call(osciRootSaga), call(dapiRootSaga)]);
  };
};
sagaMiddleWare.run(saga());

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export const resetPersistStore = async () => {
  store.dispatch(airAwardPnrReset());
  store.dispatch(airAwardReviewPnrRMReset());
  store.dispatch(airAwardReviewPnrCancelReset());
  store.dispatch(retrivePNRReset());
  store.dispatch(airAwardBookingReset());
  store.dispatch(airAwardPriceReset());
  store.dispatch(airAwardTstReset());
  store.dispatch(airAwardReviewPnrReset());
  store.dispatch(savePageId(""));
  await persistor.purge();
};
