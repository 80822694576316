import React, { useEffect, useState } from "react";
import TGIcon from "../tg-icon";
import styles from "./tg-toggle.module.scss";
import TGIconLabel from "../tg-icon-label";
import { t } from "i18next";

interface Props {
  position?: string;
  className?: string;
  iconClassName?: string;
  icon?: string;
  fillColor?: string;
  size?: string;
  onClick?: (e: any, prevState: any) => void;
  label?: string;
  variant: string;
  text?: string;
  buttonExists: boolean;
  customToggleButtonClass?: string;
  customToggleSliderClass?: string;
  active?: any;
  setActive?: Function;
  isInfoIcon?: boolean;
  onInfoClick?: any;
}

const TGToggle: React.FC<Props> = (props: Props) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const handleToggle = (e: any): void => {
    setIsActive((prevState) => {
      if (props.onClick) {
        props?.onClick(e, !prevState);
      }
      return !prevState;
    });
  };

  useEffect(() => {
    if (props.active) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [props.active]);
  return (
    <>
      {props.variant === "primary" && (
        <div className={`${styles.toggleContainer} tg-toggle`}>
          <TGIconLabel
            position={props.position ? props.position : "left"}
            icon={props.icon ? props.icon : ""}
            label={props.label ? props.label : ""}
            size={props.size ? props.size : "18"}
            className="labelAlignment"
          />
          {props.buttonExists && (
            <button
              className={`${styles.toggleButton} ${
                styles[
                  props.customToggleButtonClass
                    ? props.customToggleButtonClass
                    : ""
                ]
              } ${isActive ? styles.active : ""}`}
              onClick={handleToggle}
              aria-label="toggle-button"
            >
              <span
                className={`${styles.slider} ${
                  styles[
                    props.customToggleSliderClass
                      ? props.customToggleSliderClass
                      : ""
                  ]
                }`}
              ></span>
            </button>
          )}
          {!props.buttonExists && (
            <span
              className={`${styles[props.className ? props.className : ""]}`}
            >
              {props.text}
            </span>
          )}
        </div>
      )}
      {props.variant === "secondary" && (
        <div className={`${styles.toggleContainer} tg-toggle`}>
          <span>
            {props.label}{" "}
            {props?.isInfoIcon && <span className={styles["info-icon"]} onClick={props?.onInfoClick}>
              <TGIcon icon="information-icon" size="" fillColor="" />
            </span>}
          </span>
          <button
            className={`${styles.toggleButton} ${
              styles[
                props.customToggleButtonClass
                  ? props.customToggleButtonClass
                  : ""
              ]
            } ${isActive ? styles.active : ""}`}
            onClick={handleToggle}
            aria-label="toggle-button"
          >
            <span
              className={`${styles.slider} ${
                styles[
                  props.customToggleSliderClass
                    ? props.customToggleSliderClass
                    : ""
                ]
              }`}
            ></span>
          </button>
        </div>
      )}
    </>
  );
};

export default TGToggle;
