import React, { useEffect, useRef } from "react";
import styles from "./tg-text-field.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import { FloatingLabel, Form, FormGroup, InputGroup } from "react-bootstrap";
import TGIcon from "../tg-icon";

interface Props {
  label: string;
  placeholderText: string;
  type: string;
  customInputClassName?: string;
  addonClass?: string;
  onTextChange: (e: any, label: string) => void;
  onKeyDown?: (e: any, label: string) => void;
  onMouseUp?: (e: any, label: string) => void;
  isLeftIcon?: boolean;
  iconName?: string;
  value?: any;
  variant: boolean;
  ref?: any;
  focus?: boolean;
  ellipsis?: boolean;
  maxlength?: number;
  isError?: boolean;
  errorLabel?: any;
  id?: any;
  name?: string;
}

const defaultProps: Props = {
  label: "",
  placeholderText: "",
  type: "text",
  customInputClassName: "",
  addonClass: "",
  onTextChange: () => {},
  onKeyDown: () => {},
  onMouseUp: () => {},
  isLeftIcon: false,
  variant: false,
  ref: null,
  id: "",
  focus: false,
  ellipsis: false,
  maxlength: 20,
  isError: false,
  errorLabel: "",
  name: ""
};

const TGTextField: React.FC<Props> = (props) => {
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (inputRef.current && props.id === "FlightNumber") {
      inputRef.current.focus();
    }
  }, [props.focus, props.id]);
  const renderLabel = (variant: boolean) => (
    <>
      {!variant && (
        <>
          <TGIcon icon={props.iconName || ""} size="18" fillColor="" />
          <span className={styles.label}>{props.label}</span>
        </>
      )}
      {variant && (
        <>
          <span>
            <TGIcon icon={props.iconName || ""} size="18" fillColor="" />
          </span>
          <span
            className={`${styles.label} ${
              props.ellipsis ? styles.ellipsisStyle : ""
            }`}
          >
            {props.label}
          </span>
        </>
      )}
    </>
  );

  return (
    <FormGroup
      controlId="exampleFormInput"
      className={`${!props.variant ? "mb-3" : ""} ${
        styles.inputGroupContainer
      } ${
        styles[props.customInputClassName ? props.customInputClassName : ""]
      }`}
    >
      <FloatingLabel
        controlId="floatingInput"
        label={renderLabel(props.variant)}
        className={`${!props.variant ? styles.customFloatingInput : ""}`}
      >
        {!props.variant && (
          <Form.Control
            placeholder={props.placeholderText}
            type={props.type}
            className={styles.customFormControl}
            value={props.value?.toUpperCase()}
            onChange={() => {}}
            onClick={(e) => props.onTextChange(e, props.label)}
            ref={props.ref}
            autoComplete="off"
            name={props?.name}
          />
        )}
        {props.variant && (
          <>
            <Form.Control
              placeholder={props.placeholderText}
              type={props.type}
              className={`${styles.customFormControl}  ${
                props.isError && styles["tg-input-error"]
              }`}
              value={props.value?.toUpperCase()}
              onChange={(e) => props.onTextChange(e, props.label)}
              onClick={() => {}}
              aria-label={`input ${props.label}`}
              id={props.label}
              ref={props.ref || inputRef}
              autoFocus={props.focus}
              maxLength={props.maxlength}
              onKeyDown={(e) => props.onKeyDown(e, props.label)}
              onMouseUp={(e) => props.onMouseUp(e, props.label)}
              autoComplete="off"
              name={props?.name}
            />

            {props.isError && (
              <div className={styles["tg-input-error-icon"]}>
                <TGIcon icon="error-icon" fillColor="" size="16" />
              </div>
            )}
          </>
        )}
      </FloatingLabel>
      {props.isError && (
        <div className={`${styles["tg-input-error-label"]}`}>
          {props.errorLabel}
        </div>
      )}
    </FormGroup>
  );
};

TGTextField.defaultProps = defaultProps;

export default TGTextField;
