import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Form } from "react-bootstrap";
import TGIcon from "../tg-icon";
import styles from "./tg-typeahead.module.scss";
import useScreenSize from "../../utils/hook/useScreenSize";

interface TGTypeaheadProps {
  id: string;
  labelKey: (option: any) => string;
  options: any[];
  selected: any[];
  placeholder?: string;
  onChange: (selected: any[]) => void;
  selectedValue?: string;
  isOpen: boolean;
  setIsOpen: (id: string) => void;
  error?: string;
  showImage?: boolean;
  disabled?: boolean;
  onInputChange?: (id: string) => void;
  className?: string;
}

const TGTypeahead: React.FC<TGTypeaheadProps> = ({
  id,
  labelKey,
  options,
  onChange,
  selected,
  placeholder = "",
  selectedValue,
  isOpen,
  setIsOpen,
  error,
  showImage = false,
  disabled = false,
  onInputChange,
  className,
}) => {
  const toggleDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(isOpen ? "" : id);
  };
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  return (
    <Typeahead
      className={className}
      id={id}
      labelKey={labelKey}
      options={options}
      onChange={onChange}
      onInputChange={onInputChange}
      selected={selected}
      placeholder={selectedValue || placeholder}
      open={isOpen}
      onBlur={() => setIsOpen("")}
      renderMenuItemChildren={(option: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {showImage && (
            <img
              src={option.image}
              alt={labelKey(option)}
              style={{ width: "30px", marginRight: "10px" }}
            />
          )}
          <span>{labelKey(option).toUpperCase()}</span>
        </div>
      )}
      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          {selected.length > 0 && showImage && (
            <div
              style={{
                position: "absolute",
                left: "10px",
                pointerEvents: "none",
              }}
            >
              <img
                src={selected[0].image}
                alt={labelKey(selected[0])}
                style={{ width: "30px", height: "20px" }}
              />
            </div>
          )}
          <Form.Control
            {...inputProps}
            ref={(node: HTMLInputElement) => {
              inputRef(node);
              referenceElementRef(node);
            }}
            name={id}
            value={
              selected.length > 0
                ? labelKey(selected[0]).toUpperCase()
                : (inputProps.value?.toUpperCase() as string)
            }
            style={{
              flex: 1,
              paddingLeft: selected.length > 0 && showImage ? id=="country" ? isDesktopView ? "15%" :"8%" : isDesktopView ? "35%" : "15%"  : "12px",
            }}
            className={`${
              error ? styles.tgInputError : styles["tg-select-box"]
            }`}
            onClick={toggleDropdown}
            disabled={disabled}
          />
          {error && (
            <div
              className={styles.errorIcon}
              style={{
                position: "absolute",
                right: "30px",
                pointerEvents: "none",
                color: "#000",
                fontSize: "16px",
              }}
            >
              <TGIcon icon="error-icon" fillColor="" size="16" />
            </div>
          )}
          <div
            style={{
              position: "absolute",
              right: "10px",
              pointerEvents: "none",
              color: "#000",
              fontSize: "16px",
              transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
          >
            <TGIcon icon="chevron-down-icon" fillColor="" size="16" />
          </div>
        </div>
      )}
    />
  );
};

export default TGTypeahead;
